<template>
  <div class="hospitalInfo">
      <div class="content">
          <div class="brand flex01">
              <!-- <vaeHospital :hosList="hotHospitalList" ></vaeHospital> -->
                <div class="brandLeft"><img :src="dataInfo.img" class="picture" alt=""></div>
                <div class="brandRight flex02">
                    <div class="title">{{dataInfo.title}}</div>
                    <div class="address">地址：{{dataInfo.address}}</div>
                    <div class="label">
                        <span class="seletet" v-for="(item,index) in dataInfo.tag" :key="index">{{item.name}}</span>
                    </div>
                </div>
          </div>
          <div class="hospitalIntroduction">
                <div class="title">
                    <span class="biu"></span>
                    <div class="text">医院简介</div>
                </div>
                <div class="introduce">
                    <div class="banner"><img :src="dataInfo.img" class="bannerImg" alt=""></div>
                    <div class="text">{{dataInfo.introduce}}</div>
                </div>
          </div>
          <!-- <div class="trafficGuidance">
                <div class="title">
                    <span class="biu"></span>
                    <div class="text">交通指南</div>
                </div>
                <div class="map"></div>
          </div> -->
      </div>
  </div>
</template>

<script>
import {hospitalDetail} from '@/api/studentProduct'
import vaeHospital from '@/components/hospitalList.vue'
export default {
    components: {
        vaeHospital
    },
    data (){
        return {
            dataInfo:{},
            hotHospitalList:[]
        }
    },
    created() {
        const id = this.$route.query.id
        this.getList(id)
    },
    methods:{
        async getList(id) {
            const res = await hospitalDetail({id:id})
            if(res.data.code == 200) {
                this.dataInfo = res.data.data
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .hospitalInfo {
        background: #f7f8f9;
        height: 100%;
        // height: calc(100vh - 145px);
        .content {
            padding: 15px;
            padding-bottom: 60px;
            .brand {
                background-color: #fff;
                padding: 15px 10px;
                border-radius: 10px;
                box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
                .brandLeft {
                    .picture {
                        width: 80px;
                        height: 80px;
                        border-radius: 10px;
                        overflow: hidden;
                        // border: 1px solid #ccc;
                    }
                }
                .brandRight {
                    padding: 0 10px 5px;
                    .title {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .policy {
                        font-size: 12px;
                        font-weight: 400;
                        .card {
                            margin-right: 10px;
                        }
                    }
                    .address {
                        font-size: 12px;
                        color: #333;
                        line-height: 20px;
                    }
                    .label {
                        font-size: 10px;
                        .seletet {
                            display: inline-block;
                            text-align: justify;
                            text-justify: newspaper;
                            word-break: break-all;
                            padding: 0 13px;
                            background: #cddbf6;
                            border-radius: 15px;
                            margin-right: 10px;
                            line-height: 20px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .hospitalIntroduction {
                margin-top: 20px;
                .introduce {
                    margin-top: 10px;
                    padding: 16px 10px;
                    background-color: #fff;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
                    .banner {
                        width: 100%;
                        .bannerImg {
                            width: 100%;
                        }
                    }
                    .text {
                        padding: 10px;
                        font-size: 12px;
                        color: #666666;
                        line-height: 22px;
                    }
                }
            }
            .trafficGuidance {
                // 地图
                margin-top: 20px;
                .map {
                    margin-top: 10px;
                    background-color: #fff;
                    padding: 20px 10px;
                    box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
                    border-radius: 10px;
                }
            }
        }
        .flex01 {
            display: flex;
        }
        .flex02 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .title {
            color: #333333;
            font-size: 20px;
            font-weight: bold;
            border-radius: 2px;
            height: 20px;
            display: flex;
            align-items: center;
            .biu {
            width: 4px;
            height: 15px;
            background-color: #2163e9;
            border-radius: 2px;
            }
            .text {
            padding-left: 10px;
            }
        }
    }
</style>